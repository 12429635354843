.portfolio-header {
  position: sticky;
  top: 0;
  display: flex;
  width: 80%;
  /* margin-top: 152px; */
  align-items: flex-start;
  gap: 20px;
  flex-direction: row;
  padding-top: 128px;
  padding-left: 128px;
  padding-bottom: 3%;
  justify-content: space-between;
}

.home .links {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  right: 0;
}

.home .Name {
  color: #fff;
  font-family: "JetBrains Mono";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.exp-card-block {
  flex-direction: row;
  display: flex;
  gap: 12px;
}

.exp-card {
  background-color: #4e4e4e;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  padding: 8px 1px;
  position: relative;
  /* flex-direction: column; */
  width: fit-content;
}

.card-text {
  color: #fcd925fc;
  font-family: "JetBrains Mono";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  display: inline-block;
  padding-right: 8px;
  padding-left: 8px;
}

@media (max-width: 767px) {
  .home {
    flex-direction: column;
    font-family: "JetBrains Mono";
  }

  .Portfolio {
    flex-direction: column;
    align-items: center;
    background: #1e1e1e;
  }

  .home .portfolio-header {
    width: 90%;
    background: linear-gradient(90deg, #1e1e1e 59.19%, #fcda26 49.22%);
    padding: 24px;
  }

  .Portfolio .about {
    width: 93%;
    background: #1e1e1e;
    order: 0;
    padding: 3%;
  }

  .welcome {
    align-self: stretch;
    color: #ffffff;
    font-family: "JetBrains Mono";
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: justify;
  }

  .Portfolio .about img {
    display: none;
  }

  .exp-instance img {
    display: none;
  }

  .renan {
    color: #ffffff;
    letter-spacing: 1px;
    text-align: justify;
  }

  .all-exp {
    display: flex;
    flex-direction: column;
    gap: 15%;
    order: 1;
    width: 100%;
  }

  .Portfolio .exp-instance {
    flex-direction: column;
    width: 100%;
    background: #fcda26;
    align-items: center;
    display: flex;
    margin-top: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #description-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    letter-spacing: 1px;
    gap: 12px;
    text-align: justify;
    padding: 3%;
  }

  .exp-card-block {
    flex-direction: row;
    display: flex;
    gap: 12px;
    padding-left: 3%;
  }

  .exp-card {
    background-color: #4e4e4e;
    border-radius: 20px;
    display: flex;
    gap: 10px;
    padding: 8px 1px;
    position: relative;
    /* flex-direction: column; */
    width: fit-content;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .home {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
  .Portfolio {
    text-align: center;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    width: 100%;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .Portfolio .about {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    left: 5%;
    /* top: 246px; */
    width: 50%;
    position: fixed;
  }

  .portfolio .welcome {
    align-self: stretch;
    color: #ffffff;
    font-family: "JetBrains Mono", Helvetica;
    font-size: 64px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: justify;
  }

  .Portfolio .title {
    color: #ffffff;
    font-family: "JetBrains Mono", Helvetica;
    font-size: 64px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .presentation {
    flex: none;
    align-self: stretch;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
    position: relative;
  }

  .portfolio .line-frame {
    display: flex;
    width: 1px;
    padding: 10px 0px;
    align-items: center;
    align-self: stretch;

    background: rgba(252, 218, 38, 0.99);
  }

  .Portfolio .renan-wrapper {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    gap: 10px;
    flex: 1 0 0;
  }

  .Portfolio .renan {
    flex: 1 0 0;

    color: #fff;
    text-align: justify;
    font-family: "JetBrains Mono";
    font-size: 24px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 1px;
  }

  /* .Portfolio .work-xp {
    display: flex;
    width: 490px;
    padding: 10px 0px;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    gap: 10px 24px;
    flex-wrap: wrap;
  } */
  .all-exp {
    font-family: "JetBrains Mono", Helvetica;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 64px;
    right: 128px;
    position: absolute;
    top: 246px;
    width: 32%;
    letter-spacing: 1px;
  }

  .exp-instance {
    /* font-family: "JetBrains Mono", Helvetica; */
    /* align-items: flex-start; */
    display: flex;
    flex-direction: row;
    gap: 32px;
    right: 128px;
    position: static;
    top: 246px;
    width: 100%;
    letter-spacing: 1px;
  }

  .exp-card-block {
    flex-direction: row;
    display: flex;
    gap: 12px;
  }

  .exp-card {
    background-color: #4e4e4e;
    border-radius: 20px;
    display: flex;
    gap: 10px;
    padding: 8px 1px;
    position: relative;
    /* flex-direction: column; */
    width: fit-content;
  }

  .date-exp {
    letter-spacing: 1px;
    color: #1e1e1e8c;
    font-size: 18px;
    text-align: center;
    padding-top: 8px;
    width: 19%;
  }

  .exp-instance .exp-description {
    /* justify-content: center; */
    /* align-items: center; */
    /* gap: 10px; */
    flex: 1 0 0;
    flex-direction: column;
  }

  .exp-description #description-text {
    font-family: "JetBrains Mono", Helvetica;
    font-size: 14px;
    color: #1e1e1e;
    text-align: justify;
    letter-spacing: 1px;
    font-weight: 50;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
